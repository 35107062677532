import React from "react"
import styled from "styled-components"
import parse from "html-react-parser"
import Icon from "../atoms/icon"
import Whitebox from "../atoms/white-box"
import Text from "../atoms/text"
import { color } from "../../components/colors"
import { FlexBox } from "../../components/flexbox"
import { typography } from "../../components/typography"

const Employer = styled(FlexBox)`
  width: 50%;
  position: relative;
  overflow: hidden;

  &:not(:nth-last-child(-n+2)) {
    margin-bottom: 54px;
  }

  @media (min-width: 576px) {
    width: 33.33%;
    max-width: 100%;

    &:not(:nth-last-child(-n+2)) {
      margin-bottom: 0;
    }

    &:not(:nth-last-child(-n+3)) {
      margin-bottom: 104px;
    }
  }

  @media (min-width: 992px) {
    width: 237px;
  }

  @media (min-width: 1032px) {
    &:not(:nth-last-child(-n+2)) {
      margin-bottom: 0;
    }

    &:not(:nth-last-child(-n+3)) {
      margin-bottom: 0;
    }

    &:not(:nth-last-child(-n+5)) {
      margin-bottom: 104px;
    }
  }

  i, p {
    position: relative;
  }
`

const Card = ({ data, icon, dataId }) => (
  <Employer column center p="0 15px" data-id={dataId}>
    <Icon size="64px" background={color.green100} border={`1px solid rgba(${hexToRgb(color.green80)}, 0.25)`} radius="24px" shadow={`0px 12px 24px rgba(${hexToRgb(color.green40)}, 0.1)`} p="9px" mb="16px">
      <img src={data.uslugiIkona.sourceUrl} />
    </Icon>
    <Text typography={typography.bodyS} center>{data.uslugiOpis}</Text>
  </Employer>
)

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if(result){
      var r= parseInt(result[1], 16);
      var g= parseInt(result[2], 16);
      var b= parseInt(result[3], 16);
      return r+","+g+","+b;//return 23,14,45 -> reformat if needed 
  }
  return null; 
}

export default Card
