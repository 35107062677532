import React from "react"
import styled from "styled-components"
import Card from "../molecules/card";
import DoctorSVG from "../../images/doctor.inline.svg"
import Heading from "../atoms/heading";
import { FlexBox } from "../../components/flexbox";
import { color } from "../../components/colors";

const Wrapper = styled.div`
  width: 100%;
`

const ServicesBoxes = ({ data }) => (
    <Wrapper>
        <FlexBox justify="center" wrap width="100%" pt="40px" pMD="40px 25px" m="0 auto 85px">
            {data.uslugiUsluga.map((usluga, i) => (
                <Card data={usluga} icon={<DoctorSVG />} dataId={i} />
            ))}
        </FlexBox>
    </Wrapper>
)

export default ServicesBoxes
