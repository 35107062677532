import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Container from "../../partials/container"
import { color } from "../../../components/colors"
import Button from "../../atoms/button"
import Icon from "../../atoms/icon"
import CircleSVG from "../../../images/circle-top.inline.svg"
import HexesSVG from "../../../images/hexes-top.inline.svg"
import Hexes2SVG from "../../../images/hexes-top-2.inline.svg"
import ImgElSVG from "../../../images/top-img-el.inline.svg"
import ImgEl2SVG from "../../../images/top-img-el-2.inline.svg"
import ImgEl3SVG from "../../../images/top-img-el-3.inline.svg"
import CrossSVG from "../../../images/cross.inline.svg"
import Heading from "../../atoms/section-title"
import Text from "../../atoms/text"
import { typography } from "../../../components/typography"

const HomepageTopSection = ({ data }) => (
  <Top id="home">
    <Icon
      icon={<CircleSVG />}
      height="556px"
      width="740px"
      absolute
      top="105px"
      left="0"
    />
    <Icon
      icon={<HexesSVG />}
      height="352px"
      width="268px"
      absolute
      top="105px"
      right="0"
    />
    <Icon
      icon={<Hexes2SVG />}
      height="269px"
      width="268px"
      absolute
      left="0"
      bottom="0"
    />
    <Overlay className="top-overlay">
      <Container className="top-content">
        <Content>
          <Heading
            size="1"
            mb="33px"
          >{`Opieka dla <br><span>Twojej rodziny</span>`}</Heading>
          <Text typography={typography.bodyL} mb="64px">
            Łączymy wysoki poziom usług medycznych z indywidualnym, przyjaznym
            podejściem do problemów zdrowotnych każdego pacjenta. U nas pacjent
            decyduje o wyborze lekarza.
          </Text>
          <Button
            content={{
              text: "Rejestracja i recepty on-line",
              url: "https://lekarzebezkolejki.pl/NzozMedycySp.ZO.O.Szczecin",
            }}
            radius="56px"
            p="12px 20px"
            pSM="14px 26px"
            pMD="16px 32px"
            m="0 auto 70px 0"
            arrow
            tel
          />
        </Content>
        <TopImage>
          <Shape></Shape>
          <ImgEl2>
            <ImgEl2SVG />
          </ImgEl2>
          <Img
            fixed={data.zdjecieGlowneObraz.localFile.childImageSharp.fluid}
          />
          <ImgEl>
            <ImgElSVG />
          </ImgEl>
          <ImgEl3>
            <ImgEl3SVG />
          </ImgEl3>
          <Icon
            icon={<CrossSVG />}
            height="24px"
            width="24px"
            absolute
            top="168px"
            left="120px"
          />
          <Icon
            icon={<CrossSVG />}
            height="16px"
            width="16px"
            absolute
            bottom="245px"
            right="-22px"
          />
        </TopImage>
      </Container>
    </Overlay>
  </Top>
)

export default HomepageTopSection

const Top = styled.section`
  width: 100%;
  position: relative;
  background-color: ${color.neutral90};

  .custom-bg {
    padding-top: 105px;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;

    .gatsby-image-wrapper {
      display: block !important;
      width: 100%;
      height: 100%;

      // img {
      //   object-position: center right !important;
      // }
    }
  }
`

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 105px;

  .top-content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  &.form-overlay {
    padding: 100px 0 60px;
    position: static;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 15px 15px 0;

  @media (min-width: 769px) {
    width: 50%;
    padding: 150px 0 100px;
  }
`

const TopImage = styled.div`
  display: none;
  justify-content: center;
  align-items: flex-end;
  width: 50%;
  max-width: 570px;
  padding: 15px 0 125px 15px;
  position: relative;

  @media (min-width: 769px) {
    display: flex;
  }

  .gatsby-image-wrapper {
    height: 100%;
    //width: 73.52%;
    width: 65.52%;
    left: 3%;

    img {
      object-fit: contain !important;
      object-position: center bottom !important;
    }
  }
`

const Shape = styled.div`
  width: 100%;
  background: linear-gradient(56.58deg, #d9f2e6 0%, #66cc99 100%);
  box-shadow: 16px 16px 32px rgba(0, 153, 76, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 1132px 1000px 1000px 24px;
  position: absolute;

  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 81.23%;
  }
`

const ImgEl = styled.div`
  width: 295px;
  height: 156px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 16px 16px 32px rgba(0, 153, 76, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  position: absolute;
  right: -5%;
  bottom: 75px;

  svg {
    margin-top: 20px;
  }
`

const ImgEl2 = styled.div`
  display: flex;
  align-items: flex-end;
  width: 136px;
  height: 164px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 16px 16px 32px rgba(0, 153, 76, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  position: absolute;
  left: 11%;
  top: 42%;
  transform: translateY(-50%);
`

const ImgEl3 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 182px;
  height: 208px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 16px 16px 32px rgba(0, 153, 76, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  position: absolute;
  top: 75px;
  right: 0;
`

const Title = styled.h1`
  color: ${color.neutral20};
  font-family: Poppins, sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  max-width: 835px;

  @media (min-width: 576px) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (min-width: 769px) {
    font-size: 60px;
    line-height: 72px;
  }

  span {
    color: ${color.green20};
  }
`
