import React, { useState } from "react"
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import Container from "../../partials/container";
import { color } from "../../../components/colors"
import SectionTitle from "../../atoms/section-title";
import WhiteBox from "../../atoms/white-box";
import ServicesBoxes from "../../organisms/services-boxes";
//import { Parallax, Background } from "react-parallax"

const Services = styled.section`
  width: 100%;
  position: relative;
  padding: 24px 0 16px;

  @media (min-width: 769px) {
    padding: 90px 0 18px;
  }
`

const HomepageServicesSection = ({ data }) => {
  return (
    <Services id="nasza-oferta">
      <Container>
        <WhiteBox ptMD="40px" hideBox showBoxMD>
          <SectionTitle title="Nasza oferta" center mb="50" upperline lineWidth="48px" lineCenter />
          <ServicesBoxes data={data} />
        </WhiteBox>
      </Container>
    </Services>
  )
}
export default HomepageServicesSection