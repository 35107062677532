import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Top from "../atomic/sections/homepage/top"
import About from "../atomic/sections/homepage/about"
import Info from "../atomic/sections/homepage/info"
import Services from "../atomic/sections/homepage/services"
import Team from "../atomic/sections/homepage/team"
import Contact from "../atomic/sections/homepage/contact"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title={data.wpPage.ustawienia.ustawieniaTytulStrony} />
    <Top data={data.wpPage.zdjecieGlowne} />
    <About data={data.wpPage.oNas} />
    <Info data={data.wpPage.informacje} />
    <Services data={data.wpPage.uslugi} />
    <Team data={data.wpPage.kadra} />
    <Contact data={data.wpPage.kontakt} />
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query WordpressAktyPrawne {
    wpPage(id: { eq: "cG9zdDoy" }) {
      title
      ustawienia {
        ustawieniaTytulStrony
        ustawieniaOpisStrony
        ustawieniaKolorPrzewodni
        ustawieniaKolorDodatkowy
      }
      zdjecieGlowne {
        zdjecieGlowneObraz {
          sourceUrl
          localFile {
            childImageSharp {
              fluid(maxWidth: 630) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
      oNas {
        oNasTytul
        oNasTekst
        oNasZdjecie {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          sourceUrl
        }
      }
      informacje {
        infoTytul
        infoTekst
        infoZdjecie {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          sourceUrl
        }
      }
      kadra {
        kadraTytul
        kadraKategoria {
          kadraKategoriaNazwa
          kadraKategoriaOsoba {
            kadraKategoriaImie
            kadraKategoriaGabinet
            kadraKategoriaStanowisko
            kadraKategoriaZdjecie {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              sourceUrl
            }
          }
        }
      }
      uslugi {
        uslugiUsluga {
          uslugiIkona {
            mediaDetails {
              file
            }
            mediaItemUrl
            sourceUrl
          }
          uslugiOpis
        }
      }
      kontakt {
        col1 {
          fieldtext
          fieldtype
          fieldvalue
        }
        col2 {
          fieldtext
          fieldtype
          fieldvalue
        }
        col3 {
          fieldtext
          fieldtype
          fieldvalue
        }
      }
    }
  }
`
