import React from "react"
import styled from "styled-components"
import { color } from "../../../components/colors"
import Container from "../../partials/container"
import SectionTitle from "../../atoms/section-title"
import Text from "../../atoms/text"
import Link from "../../atoms/link"
import { FlexBox } from "../../../components/flexbox"
import PhoneSVG from "../../../images/phone.inline.svg"
import MailSVG from "../../../images/mail.inline.svg"
import { typography } from "../../../components/typography"
import Icon from "../../atoms/icon"

const Contact = styled.section`
  padding: 48px 0 0;
  position: relative;

  @media (min-width: 769px) {
    padding: 80px 0;
  }
`

const ContactSection = ({ className, data }) => {
  const { col1, col2, col3 } = data
  const columns = [col1, col2, col3]
  return (
    <Contact id="kontakt" className={className}>
      <Container>
        <SectionTitle
          title="Dane kontaktowe"
          upperline
          lineWidth="48px"
          mb="24px"
          mbMD="64px"
        />
        <FlexBox
          column
          directionMD="row"
          justify="space-between"
          mb="24px"
          mbMD="80px"
        >
          {console.log(columns)}
          {columns.map((column, columnIndex) => (
            <FlexBox
              column
              width="100%"
              widthMD="30%"
              maxWidthMD="253px"
              mb="20px"
              mbMD="0"
              key={columnIndex}
            >
              {column &&
                column.map((item, index) =>
                  item.fieldtype === "title" ? (
                    <SectionTitle
                      size="3"
                      typography={typography.h400}
                      title={item.fieldtext}
                      pt="0"
                      pb="8px"
                      key={index}
                    />
                  ) : item.fieldtype === "phone" ? (
                    <Link
                      flex
                      href={`tel:48${item.fieldtext.replace(/ /g, "")}`}
                      color={color.neutral20}
                      mb="8px"
                      noParser
                      key={index}
                    >
                      <Icon icon={<PhoneSVG />} size="22px" mr="10px" />
                      {item.fieldtext}
                    </Link>
                  ) : item.fieldtype === "mail" ? (
                    <Link
                      flex
                      href={`mailto:${item.fieldtext}`}
                      noParser
                      key={index}
                    >
                      <Icon icon={<MailSVG />} size="22px" mr="10px" />
                      {item.fieldtext}
                    </Link>
                  ) : item.fieldtype === "break" ? (
                    <div
                      style={{ marginBottom: item.fieldvalue }}
                      key={index}
                    />
                  ) : (
                    <Text mb="8px" mbMD="16px" key={index}>
                      {item.fieldtext}
                    </Text>
                  )
                )}
            </FlexBox>
          ))}
        </FlexBox>
      </Container>
      <Container noPadding>
        <div style={{ height: "100%", width: "100%" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2376.8534245612605!2d14.537852!3d53.435329!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47aa093f30e95be5%3A0xee95bdac324113c2!2sMedycy%20-%20Niepubliczny%20zak%C5%82ad%20opieki%20zdrowotnej!5e0!3m2!1spl!2spl!4v1649125306117!5m2!1spl!2spl"
            width="600"
            height="450"
            frameborder="0"
            style={{
              position: "relative",
              width: "100%",
              display: "block",
              height: "400px",
              border: "0",
            }}
            allowfullscreen=""
          ></iframe>
        </div>
      </Container>
    </Contact>
  )
}

export default ContactSection
